:root {
    color-scheme: only light;
}

.global-text-heading {
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.global-text-body {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.global-pageheader {
    font-size: 64px;
    font-style: italic;
    font-weight: 900;
    line-height: normal;
}

@media (max-width: 992px) {
    .global-pageheader {
        font-size: 32px;
    }
}