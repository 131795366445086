.pageheader-navbar > div:not(:last-child) {
    border-right: solid 2px var(--bs-primary-text-emphasis)
}

.pageheader-navbar > div > a {
    position: relative;
}
.pageheader-navbar > div > a::before {
    width:0;
    transition: width 0.2s;
    background-color: black;
    height: 2px;
    bottom: -2px;
    position:absolute;
    display:block;
    content: "";
}
.pageheader-navbar > div > a:hover::before, .pageheader-navbar > div > .pageheader-navbar-current::before {
    width: 100%;
}

.pageheader-nav {
    font-family: Roboto;
    font-size: 36px;
    font-weight: 400;
    text-decoration: none;
}

.mobile-navbar {
    transition: max-height 0.2s;
    overflow: hidden;
    max-height: 0;
}

.mobile-navbar:not(.is-open) {
    padding:0 !important;
}

.mobile-navbar.is-open {
    opacity: 1;
    max-height: fit-content;
}

.mobile-nav-item {
    text-decoration: none;
}