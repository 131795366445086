.burgermenu-container {
    position: relative;
    width:30px;
    height:22px;
    /* border: 1px solid black; */
}

/* .burgermenu-container:not(.burgermenu-is-open) {
    display: flex;
    flex-direction: column;
    gap: 5px;
} */

.burgermenu-container.burgermenu-is-open > span {
    display:block;
}

.burgermenu-container > span {
    width: 30px;
    height: 4px;
    background-color: var(--bs-primary-bg-subtle);
    border-radius: 2px;
    transition: transform 0.2s, opacity 0.2s;
    position:absolute
}

.burgermenu-container > span:nth-child(1) {
    top:0;
}

.burgermenu-container > span:nth-child(2) {
    top:9px;
}

.burgermenu-container > span:nth-child(3) {
    top:18px
}

.burgermenu-container.burgermenu-is-open > span:nth-child(1) {
    transform: rotateZ(45deg) translateX(5px) translateY(7px);
}

.burgermenu-container.burgermenu-is-open > span:nth-child(2) {
    opacity: 0;
}

.burgermenu-container.burgermenu-is-open > span:nth-child(3) {
    transform: rotateZ(-45deg) translateX(7px) translateY(-7px);
}
